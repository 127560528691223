const dev = {
    dev: true,
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://x4g1cr51th.execute-api.us-east-1.amazonaws.com/dev"
    }
  };
  
  const prod = {
    dev: false,
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://l5t0p2om9k.execute-api.us-east-1.amazonaws.com/prod"
    }
  };
  
  // Default to dev if not set
  const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;
  
  export default {
    // Add common config values here
    ...config
  };