import React from "react";

import Typography from '@mui/material/Typography';
import { 
    Box,
    Card,
    CardContent
} from "@mui/material";

export default function About() {

    return (
        <Card variant="outlined" sx={{ marginTop: '1em'}}>
            <CardContent>
                <Box style={{fontStyle: "italic", paddingLeft: "0.5em", borderLeft: "1px solid white", fontVariantCaps: "small-caps"}}>
                    "In a time of deceit telling the truth is a revolutionary act." - George Orwell
                </Box>
                <Typography sx={{ mb: 1.5, mt: 1.5 }}>
                    Censorship and corruption run amok in our societies.  Out of the darkness, a group of individuals have emerged who dare seek the truth.  Truthseekrs starts
                    by amplifying their voices and growing their audience.
                </Typography>

                <Typography sx={{ mb: 1.5 }}>
                    We won't stop there.  Our vision is to create an ecosystem which connects those brave enough to tell the truth with those courageous enough to hear it.
                </Typography>

                <Typography sx={{ mb: 1.5 }}>
                    Help us continue to grow and together we will build a platform with the power to overcome the forces acting against us.
                </Typography>

                <Typography>
                    The truth will not be silenced.
                </Typography>

            </CardContent>
        </Card>
        
    );
}