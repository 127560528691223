import React from "react";

import { API } from "aws-amplify";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
    Box,
    CardActionArea 
} from "@mui/material";

import "./Home.css";

export default function Home() {
    const [content, setContent] = React.useState([]);
    let offset = 0;

    React.useEffect(() => {
        window.addEventListener('scroll', scrolling_function);

        getContent();
    }, []);

    const scrolling_function = () => {
        if((window.innerHeight + window.scrollY) >= document.body.offsetHeight-1000){
            getContent().then(
                function(value) { window.addEventListener('scroll', scrolling_function) },
                function(error) { /* code if some error */ });
            window.removeEventListener('scroll',scrolling_function)
        }
    }

    const getContent = async () => {
        console.log(`calling with offset = ${offset}`)
        const request = {
            queryStringParameters: {
              offset
            }
        };
        const res = await API.get("api", "/content", request);
        if (res.length > 0) {
            setContent(content => [...content, ...res]);
            offset = offset + 20;
        }

        return res;
    };
    
    const getUrl = (item) => {
        if (item.platform_id === 1) {
            return `https://rumble.com/${item.href}`;
        } else if (item.platform_id === 2) {
            return `https://${item.key}.substack.com/p/${item.href}`;
        } else {
            return `https://www.youtube.com/watch?v=${item.href}`;
        }
    };

    return (
        content.map((item) => (
            <Card variant="outlined" sx={{ marginTop: '1em'}}>
                <CardActionArea rel="noopener" target="_blank" href={getUrl(item)}>
                    <CardContent>
                        <Box className='flexBox' sx={{display: 'flex'}}>
                            <CardMedia
                                className="previewImage"
                                component="img"
                                sx={{ height: 181}}
                                image={item.image_url}
                            />
                            <Box className="cardContent" sx={{ flex: '1 1 auto'}}>
                                <CardMedia
                                    component="img"
                                    sx={{ float: 'right', width: 32}}
                                    image={item.platform_icon}
                                />
                                <Typography gutterBottom variant="h5" component="div">
                                    {item.title}
                                </Typography>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <CardMedia
                                        component="img"
                                        sx={{ borderRadius: '50%', width: 64, marginRight: '1em'}}
                                        image={item.creator_icon}
                                    />
                                    <Typography variant="body" color="text.secondary" component="span" sx={{lineHeight: '4em'}}>
                                        {item.name}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
            )
        )
    );
}