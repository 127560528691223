import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "./containers/About";
import Contact from "./containers/Contact";
import Donate from "./containers/Donate";
import Home from "./containers/Home";

export default function Links() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/donate" element={<Donate />} />
    </Routes>
  );
}