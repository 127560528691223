import React from "react";

import EmailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';
import { 
    Card,
    CardActionArea,
    CardContent
} from "@mui/material";

export default function Contact() {

    return (
        <Card variant="outlined" sx={{ marginTop: '1em'}}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }}>
                    A platform is only as strong as it's users.  As such, we would love to hear from you.
                    Please send any questions, feedback, or concerns you may have.  Missing a vital platform or 
                    an important content creator, let us know.  Have a feature request or bug report, we want 
                    to hear about those as well.
                </Typography>
                <Card sx={{ maxWidth: 350, margin: "auto"}}>
                    <CardActionArea rel="noopener" target="_blank" href="mailto:info@truthseekrs.com">
                        <CardContent sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <EmailIcon fontSize="large" />
                            <Typography variant="body" sx={{p: 0, margin: "auto"}}>
                                Contact Us
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </CardContent>
        </Card>
        
    );
}