import React from "react";

import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import Typography from '@mui/material/Typography';
import { 
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider
} from "@mui/material";

export default function Donate() {

    const SUBSCRIBESTAR_URL = "https://www.subscribestar.com/truthseekrs";
    const BITCOIN_URL = "bitcoin:13p9pdtiFzdHhQjky3xLT9SmzKAoB5Ba5M";

    return (
        <Card variant="outlined" sx={{ marginTop: '1em'}}>
            <CardContent>
                <Typography sx={{ mb: 1.5 }}>
                    If you believe what we are doing is important and you find this platform valuable, please consider a donation (even just a dollar a month).
                </Typography>
                <Typography sx={{ mb: 1.5 }}>
                    Our grand ambitions for this plaform will be met much quicker with adequate financial backing.
                </Typography>
                <Card sx={{ maxWidth: 350, margin: "auto"}}>
                    <CardActionArea rel="noopener" target="_blank" href={SUBSCRIBESTAR_URL}>
                        <CardContent sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <img src="https://ss-staging-assets.s3-us-west-1.amazonaws.com/ss_sign.png" width="35" />
                            <Typography variant="body" sx={{p: 0, margin: "auto"}}>
                                Donate with SubcribeStar
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                <Divider variant="middle" />
                    <CardActionArea rel="noopener" target="_blank" href={BITCOIN_URL}>
                        <CardContent sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <CurrencyBitcoinIcon fontSize="large" />
                            <Typography variant="body2" sx={{p: 0, margin: "auto"}}>
                            13p9pdtiFzdHhQjky3xLT9SmzKAoB5Ba5M
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </CardContent>
        </Card>
        
    );
}